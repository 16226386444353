.tableAction{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.tableAction img[alt="menu"]{
    width: 20px;
    height: 20px;
}


.tableAction .menu{
    background-color: #ffffff;
    position: absolute;
    min-width: 140px;
    top:40px;
    right: 5px;
    z-index: 11;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);

}


.tableAction .menu a{
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 15px;
    color: inherit;
    text-align: inherit;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    border: 0;
}

.tableAction .menu a:hover{
    background: #f7f7f7;
    cursor: pointer;
}

.tableAction .menu p{
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 15px;
    color: inherit;
    text-align: inherit;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    border: 0;
}

.tableAction .menu p:hover{
    background: #f7f7f7;
    cursor: pointer;
}