div.DraftEditor-root {
    background-color: #f3f3f3;
    width:100%;
    margin:auto;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.5);
    height:250px;
    overflow-y: scroll;
    padding:20px;
    font-size: 18px;
    font-family: 'calibri', sans-serif;
    border: '1px dotted black'
  }

  .rdw-editor-main {
    border:'1px dotted #ccc'
  }