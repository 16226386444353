:root {
    --white-color: #fff;
    --sidebar-color: #f9f9fa;
    --off-white: #f9f9fa;
    --text-color: #26273c;
    --light-color: #555;
    --lite-color: #f2f2f2;
    --border-color: #efefef;
    --outline-color: #d2d2d2;
    --primary-color: #767dff;
    --red-color: #ff6464;
    --success-color: #38ada9;
    --force-white: #fff;
    --blue-color: #3e8fdc;
    --green-color: #198754;
}

#traveldesk{
    position: relative;
    display: flex;
    background: #FAFAFA;
    flex-direction: row;
    --border-color: #efefef;
}

#traveldesk #minisidebar{
    background: #202c8a;
    height: 100vh;
    overflow-y: auto;
    width: 70px;
}

#traveldesk .prime-color{
    color: #321CBF;
}

#traveldesk .secondary-color{
     color: #7558F6;
 }
#traveldesk .prime-background{
    background: #321CBF;
}


#traveldesk #minisidebar .icon{
    width: 42px;
    height: 42px;
    margin: 20px 14px 0;
    cursor: pointer;
    padding:10px;
    border-radius: 21px;
}

#traveldesk #minisidebar .icon img{
    width: 22px;
    height: 22px;
}

.none{
    display: none;
}
#traveldesk .onhover:hover .hoverable{
    position: absolute;
    left : -12px;
    top: 12px;
    width:5px;
    height:5px;
    border-radius: 2.5px;
    background: #FBBD40;
}


.shine {
    background: #f6f6f6;
    position: relative;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}


/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #795BF7;
    color: #795BF7;
    box-shadow: 9999px 0 0 -5px #795BF7;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #795BF7;
    color: #795BF7;
}

.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #795BF7;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #795BF7;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore {
    0% {
        box-shadow: 9984px 0 0 -5px #795BF7;
    }
    30% {
        box-shadow: 9984px 0 0 2px #795BF7;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #795BF7;
    }
}

@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px #795BF7;
    }
    30% {
        box-shadow: 9999px 0 0 2px #795BF7;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #795BF7;
    }
}

@keyframes dotPulseAfter {
    0% {
        box-shadow: 10014px 0 0 -5px #795BF7;
    }
    30% {
        box-shadow: 10014px 0 0 2px #795BF7;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #795BF7;
    }
}


/* Dot pulse 1 with dot color #fff
*/

.dot-pulse1 {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 9999px 0 0 -5px #fff;
    animation: dotPulse1 1.5s infinite linear;
    animation-delay: .25s;
}

.dot-pulse1::before, .dot-pulse1::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
}

.dot-pulse1::before {
    box-shadow: 9984px 0 0 -5px #fff;
    animation: dotPulseBefore1 1.5s infinite linear;
    animation-delay: 0s;
}

.dot-pulse1::after {
    box-shadow: 10014px 0 0 -5px #fff;
    animation: dotPulseAfter1 1.5s infinite linear;
    animation-delay: .5s;
}

@keyframes dotPulseBefore1 {
    0% {
        box-shadow: 9984px 0 0 -5px #fff;
    }
    30% {
        box-shadow: 9984px 0 0 2px #fff;
    }
    60%,
    100% {
        box-shadow: 9984px 0 0 -5px #fff;
    }
}

@keyframes dotPulse1 {
    0% {
        box-shadow: 9999px 0 0 -5px #fff;
    }
    30% {
        box-shadow: 9999px 0 0 2px #fff;
    }
    60%,
    100% {
        box-shadow: 9999px 0 0 -5px #fff;
    }
}

@keyframes dotPulseAfter1 {
    0% {
        box-shadow: 10014px 0 0 -5px #fff;
    }
    30% {
        box-shadow: 10014px 0 0 2px #fff;
    }
    60%,
    100% {
        box-shadow: 10014px 0 0 -5px #fff;
    }
}


.loader {
    height: 6px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ac9af4;
}
.loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 300px;
    height: 6px;
    background-color: #7558F6;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

.top-badge{
    position: absolute;
    right: -5px;
    top: -2px;
    background: #7558F6;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 700;
    border: 1px solid #7558F6;
    color: #ffffff;
}
.single-line{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
}
.side-container{
    background: #fff;
    height: calc(100vh - 10px);
    overflow-y: auto;
    flex:1;
    border-top: 6px solid #7558F6;
    padding:44px 55px;
}
.border-top{
    flex:1;
    border-top: 6px solid #7558F6;
    display:flex;
}

.date-range .DateInput_input{
    width: 110px !important;
}

.right-side-bar{
    background: #F6F6F6;
    width: 400px;
    height: calc(100vh - 10px);
    overflow-y: auto;
    padding: 15px 22px;
    border-left: 1px solid #dedede;
}

.title{
    color: #321CBF;
    font-size: 23px;
    font-weight: 800;
    letter-spacing: 2px;
}

.title2{
    color: #321CBF;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 2px;
}

.sub-title{
    color: #898989;
    font-size: 11px;
    letter-spacing: 1px;
}





#luna-chat .container {
    position: relative;
    height: calc(100vh - 58px);
    overflow-y: auto;
    flex-direction: column-reverse;
    padding-bottom: 40px;
}

#luna-chat .sendMessage {
    height: 50px;
    background: white;
    z-index: 22;
    border-top: 1px solid #dedede;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
}

#luna-chat .sendMessage img {
    padding: 8px;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background: #7558F6;
    margin: 8px;
}

#luna-chat .sendMessage input {
    flex: 1;
    font-weight: 400;
    background: white;
    border: none;
    height: 30px;
    font-size: 14px;
    color: #878787;
    margin: 10px 3px 10px 12px;
}

#luna-chat .notifications {
    background: #d9eaff;
    padding: 12px;
    margin: 12px 8px 5px 5px;
    position: relative;
    max-width: 250px;
    border-radius: 15px 15px 1px 15px;
    border: 1px solid rgba(135, 150, 165, .1);
}

#luna-chat .notification {
    background: white;
    padding: 8px;
    margin: 12px 8px 5px 5px;
    position: relative;
    max-width: 400px;
    flex: 1;
    border: 1px solid rgba(135, 150, 165, .25);
}

.SingleDatePickerInput__withBorder{
    margin-top: 7px;
}
.SingleDatePicker,.SingleDatePickerInput__withBorder,.SingleDatePickerInput__withBorder.DateInput,.DateInput,.SingleDatePickerInput{
    width: 100%;
}
.DateInput_input{
    font-weight: 400;
    font-size: 14px;
    padding:8px 12px;
    line-height: 19px;
}

#luna-chat .notifications span {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #bcbcbc;
    font-size: 10px;
    font-weight: 700;
}

#luna-chat .notifications p.t {
    font-weight: 400;
    font-size: 13px;
    margin: 0;
}

#luna-chat .chat-icon {
    width: 32px;
    height: 32px;
    margin: 8px 5px 8px 8px;
}



#luna-chat .notification span {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #bcbcbc;
    font-size: 10px;
    font-weight: 700;
}

#luna-chat .notification .chat-icon {
    width: 32px;
    height: 32px;
    margin: 8px 5px 8px 8px;
}

#luna-chat .notification p.t {
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    color: #60769f;
}

#luna-chat .notification p.d {
    font-weight: 400;
    font-size: 12px;
    color: #BCC1C9;
    margin: 2px 0 0;
}





#luna-chat .card {
    box-shadow: none !important;
}


#luna-chat .container::-webkit-scrollbar {
    width: 3px;
}

#luna-chat .container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

#luna-chat .container::-webkit-scrollbar-thumb {
    background: #286efa;
}

#luna-chat .container::-webkit-scrollbar-thumb:hover {
    background: #286efa;
}



.luna_icon {
    background: #286efa;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 42;
    right: 20px;
    bottom: 20px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
    cursor: pointer;
}

.luna_icon span {
    position: absolute;
    right: -1px;
    top: -1px;
    background: rgb(246, 85, 103);
    padding: 2px 6px;
    font-size: 12px;
    color: white;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .175);
    font-weight: 800;
    border-radius: 50%;
}



#luna {
    width: 375px;
    right: 40px;
    bottom: 40px;
    background: #f2f4f8;
    height: 520px;
    position: fixed;
    z-index: 44;
    box-shadow: 0 12px 34px 0 rgba(0, 0, 0, .175)
}



#luna .title {
    background: #286efa;
    height: 50px;
    position: relative;
}

#luna .title p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    color: white;
    padding: 15px;
    text-align: left;
    letter-spacing: 1px;
    margin: 0;
    line-height: 20px;
}

#luna .title img {
    position: absolute;
    right: 12px;
    top: 18px;
    height: 14px;
    cursor: pointer;
    width: 14px;
}

#luna .icons-container {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid rgba(135, 150, 165, .35);
    display: flex;
    flex-direction: row;
    background: white;
}

#luna .icons-container .item {
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-right: 1px solid rgba(135, 150, 165, .35);
}

#luna .icons-container .item img {
    width: 12px;
    height: 12px;
}

#luna .icons-container .item p {
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
    padding-top: 1px;
}

#luna .icons-container .item.active p {
    color: #286efa;
}


.luna-item{
    width: 100%;
}

.luna-item.active .b-b{
    background: rgba(222,222,222,.3);
}
.luna-item span{
    background:  #717dfb;
    width: 10px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    padding: 0 4px;
    padding-left: 6px;
    color: white;
    font-weight: 500;
    position: absolute;
    right: 15px;
    top:20px;
    border-radius: 50%;
}

.luna-item p.top{
    font-weight: 500;
    margin: 0;
    padding: 0 12px;
    font-size: 15px;
}

.luna-item p.bottom{
    font-weight: 400;
    margin: 0;
    padding: 0 12px;
    font-size: 12px;
}

.luna-item img{
    width: 41px;
    height: 41px;
}

.luna-hover:hover {
    background-color: #F6F6F6 !important;
}


.note-container{
    background: #F6F6F6;
}



/* FlatIcons used in Travel Desk */
/* Support Hotel plus icons /app/hotel/support */

.hotelsupport {
   background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiBjbGFzcz0iIj48Zz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj4KCTxnPgoJCTxwYXRoIGQ9Ik00ODkuNjY3LDIzMy42NjdIMjc2LjMzM1YyMC4zMzNDMjc2LjMzMyw4LjU1MSwyNjYuNzgyLTEsMjU1LTFzLTIxLjMzMyw5LjU1MS0yMS4zMzMsMjEuMzMzdjIxMy4zMzNIMjAuMzMzICAgIEM4LjU1MSwyMzMuNjY3LTEsMjQzLjIxOC0xLDI1NXM5LjU1MSwyMS4zMzMsMjEuMzMzLDIxLjMzM2gyMTMuMzMzdjIxMy4zMzNjMCwxMS43ODIsOS41NTEsMjEuMzMzLDIxLjMzMywyMS4zMzMgICAgczIxLjMzMy05LjU1MSwyMS4zMzMtMjEuMzMzVjI3Ni4zMzNoMjEzLjMzM2MxMS43ODIsMCwyMS4zMzMtOS41NTEsMjEuMzMzLTIxLjMzM1M1MDEuNDQ5LDIzMy42NjcsNDg5LjY2NywyMzMuNjY3eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iI2ZmZmZmZiI+PC9wYXRoPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4=);
   background-size: cover;
   height: 15px;
   width: 15px;
}
