.input-range__label {
    color: rgba(0,0,0,0.7);
}
  
.input-range__slider {
    background: #fff;
    border-color: #c9c9c9;
}

.input-range__label--max, .input-range__label--min {
    display: none;
}

.input-range__label-container {
    color: rgba(0,0,0,.7);
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 600;
    background: #fff;
}

.input-range__label--value {
    top: 12px;
    background: transparent;
    left: 10px;
}

input[type=checkbox] {
    height: 20px;
    width: 20px
}

input[type=checkbox]:checked {
    color:#3f51b5;
    height: 20px;
    width: 20px;
}

.rateactive {
    color: #3f51b5 !important;
    border-color: #3f51b5 !important;
    font-weight: 500;
    background-color:  #eceef8 !important ;

}