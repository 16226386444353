.daterange1 .rdrDateRangePickerWrapper {
    box-shadow: none;
    border-bottom: 1px solid #efefef;

}

.rdrDateRangePickerWrapper {
    box-shadow: 1px 2px 2px rgba(1, 1, 1, .2);
}

.rdrDateRangePickerWrapper {
    position: relative;
    z-index: 5;
}